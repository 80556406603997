<template>
  <div class="survey">
    <div class="row" v-if="!isLoading">
      <div class="col-4">
        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.victim-support-centre') }}</h3>
            </div>
          </div>
          <div class="widget-body">
            <div class="form-group row mb-0">
              <label class="col-sm-4 col-form-label">{{ $t('labels.police-region') }}</label>
              <div class="col-sm-8 pt-2">
                {{ localOffice.police_region_list }}
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-sm-4 col-form-label">{{ $t('labels.county') }}</label>
              <div class="col-sm-8 pt-2">
                {{ localOffice.county_list }}
              </div>
            </div>

            <div class="form-group row mb-0">
              <label class="col-sm-4 col-form-label">{{ $t('labels.victim-support-centre') }}</label>
              <div class="col-sm-8 pt-2">
                {{ localOffice.office.label }}
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">{{ $t('labels.local-office') }}</label>
              <div class="col-sm-8 pt-2">
                <div v-for="local in localOffice.office.local_offices" :key="local.id">
                  {{ local.label }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.other') }}</h3>
            </div>
          </div>
          <div class="widget-body">
            <checkbox-field-office
                :editable="$store.getters.permissions.includes('office-section-geography-edit')"
                :model="localOffice.office"
                field="has_room_conversation"
                :label="this.$t('labels.has-room-conversation')"
            ></checkbox-field-office>
            <checkbox-field-office
                :editable="$store.getters.permissions.includes('office-section-geography-edit')"
                :model="localOffice.office"
                field="member_idea"
                :label="this.$t('labels.member-idea')">
            </checkbox-field-office>
            <checkbox-field-office
                :editable="$store.getters.permissions.includes('office-section-geography-edit')"
                :model="localOffice.office"
                field="has_employer_responsibility"
                :label="this.$t('labels.has-employer-responsibility')"
            ></checkbox-field-office>

            <text-field-office
                :editable="$store.getters.permissions.includes('office-section-geography-edit')"
                @updateLocalOffice="updateLocalOffice"
                :model="localOffice.office"
                field="another_has_employer_responsibility"
                type="text"
                :label="this.$t('labels.another-has-employer-responsibility')"
            ></text-field-office>
          </div>
        </div>
      </div>
      <div class="col bl-1">
        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.municipalities') }}</h3>
            </div>
            <div class="widget-header-toolbar">
              <span class="badge-count">{{ localOffice.municipality_list_count }}</span>
            </div>
          </div>
          <div class="widget-body pb-3">
            <div class="row">
              <div class="col-6 mb-2" v-for="municipality in localOffice.municipality" :key="municipality">
                {{ municipality }}
              </div>
            </div>
          </div>
        </div>
        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.districts') }}</h3>
            </div>
            <div class="widget-header-toolbar">
              <span class="badge-count">{{ localOffice.district_list_count }}</span>
            </div>
          </div>
          <div class="widget-body pb-3">
            <div class="text-muted pb-1" v-if="localOffice.district_list_count === 0">{{ $t('labels.no-neighborhoods-created-for') }}
              {{ localOffice.label }}.
            </div>

            <div class="row" v-if="localOffice.district_list_count !== 0">
              <div class="col-6 mb-2" v-for="district in localOffice.district" :key="district">
                {{ district }}
              </div>
            </div>
          </div>
        </div>

        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.postal-areas') }}<info-box :field="'Jourformuläret  - Geografi Postorter rubrik'" /></h3>
            </div>
            <div class="widget-header-toolbar">
              <span class="badge-count">{{ localOffice.postal_town.length }}</span>
            </div>
          </div>
          <div class="widget-body pb-3">
            <div class="row">
              <div class="col-6 mb-2" v-for="postal_town in localOffice.postal_town" :key="postal_town">
                {{ postal_town }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col bl-1">
        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.courts') }}</h3>
            </div>
            <div class="widget-header-toolbar">
              <span class="badge-count">{{ localOffice.court_list_count }}</span>
            </div>
          </div>
          <div class="widget-body pb-3">
            <div class="text-muted pb-1" v-if="localOffice.court_list_count === 0">{{ $t('labels.no-courts-created-for') }}
              {{ localOffice.label }}.
            </div>
            <div v-if="localOffice.court_list_count !== 0">
              {{ localOffice.court_list }}
            </div>
          </div>
        </div>
        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.exposed-areas') }}</h3>
            </div>
            <div class="widget-header-toolbar">
              <span class="badge-count">{{ localOffice.exposed_area_list_count }}</span>
            </div>
          </div>
          <div class="widget-body pb-3">
            <div class="row" v-if="localOffice.exposed_area_list_count !== 0">
              <div class="col-6 mb-2" v-for="exposed_area in localOffice.exposed_area" :key="exposed_area">
                {{ exposed_area }}
              </div>
            </div>

            <div class="text-muted pb-1" v-if="localOffice.exposed_area_list_count === 0">
              {{ $t('labels.no-exposed-areas-created-for') }} {{ localOffice.label }}.
            </div>
          </div>
        </div>

        <div class="widget">
          <div class="widget-header">
            <div class="widget-header-label">
              <h3>{{ $t('labels.postal-codes') }}</h3>
            </div>
            <div class="widget-header-toolbar">
              <span class="badge-count">{{ localOffice.postal_codes.length }}</span>
            </div>
          </div>
          <div class="widget-body pb-3">
            <div class="row">
              <div class="col-2 mb-2" v-for="postal in localOffice.postal_codes" :key="postal">
                {{ postal }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/ticket/localoffice/TextField';
import TextFieldOffice from '@/components/ticket/office/TextField';
import TextareaField from '@/components/ticket/localoffice/TextareaField';
import SelectField from '@/components/ticket/localoffice/SelectField';
import CheckboxField from '@/components/ticket/localoffice/CheckboxField';
import CheckboxFieldOffice from '@/components/ticket/office/CheckboxField';
import InfoBox from '@/components/InfoBox';
import BojAPI from '@/api/boj';

export default {
  name: 'survey-page',
  props: {
    id: Number,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    updateLocalOffice(ticket) {
      this.$emit('updateLocalOffice', ticket);
    },
    setData(payload) {
      this.localOffice = payload.data;
      this.isLoading = false;
    },
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getLocalOffice(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    BojAPI.getLocalOffice(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },
  components: {
    TextField,
    TextFieldOffice,
    TextareaField,
    SelectField,
    CheckboxField,
    CheckboxFieldOffice,
    InfoBox,
  },
};
</script>
